import React from 'react';
import { ButtonPriority, Divider } from 'wix-ui-tpa';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupBy } from 'lodash';
import type { GroupResponse as ApiTypesV1GroupResponse } from '@wix/ambassador-social-groups-v1-group/types';
import { GroupWrapper, isJoined } from '@wix/social-groups-api';
import {
  groupsSearchForGroup,
  groupsSelectGroupFromList,
} from '@wix/bi-logger-groups/v2';

import { Box } from 'common/components/Box/Box';
import { Search } from 'common/components/Search';
import { NoGroups } from 'common/components/GroupList/NoGroups';
import { useUser } from 'common/context/user/useUser';
import { BIUserEntry } from 'common/bi-logger/types';

import settingsParams from '../../../../settingsParams';
import { useGroupsActions } from '../../../../contexts/useGroupsActions';
import { CreateGroupButton } from '../../CreateGroupButton';

import { Groups } from './Groups';

import { st, classes } from './SideBar.st.css';

type SideBarProps = {
  onCreateButtonClick(): void;
  groups: ApiTypesV1GroupResponse[];
};

export const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const rootEl = React.useRef<HTMLElement>(null);
  // to keep the height the same between different search result
  const [height, setHeight] = React.useState('min-content');

  // for group redirect after successfully legacy join
  const [changeMembershipGroupId, setChangeMembershipGroupId] = React.useState<
    string | undefined
  >();

  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const bi = useBi();
  const { isMobile } = useEnvironment();
  const actions = useGroupsActions();
  const { userPermissions } = useUser();

  const settings = useSettings();
  const shouldShowSearch = settings.get(settingsParams.showSearchSorting);
  const shouldShowCreateGroupButton =
    settings.get(settingsParams.showCreateGroupButton) &&
    userPermissions.canCreateGroup;
  const shouldShowGroupsToJoin = settings.get(settingsParams.showGroupsToJoin);

  React.useEffect(() => {
    if (isMobile) {
      return;
    }

    if (rootEl && rootEl.current && height === 'min-content') {
      setHeight(`${rootEl.current.offsetHeight}px`);
    }
  }, [rootEl.current, isMobile]);

  React.useEffect(() => {
    const group = props.groups.find(
      (g) => g.groupId === changeMembershipGroupId,
    );
    if (group && isJoined(group)) {
      actions?.goToGroup(group.groupId as string);
    }
  }, [props.groups]);

  const groupsByMembership = React.useMemo(() => {
    return groupBy(props.groups, function isJoined(group) {
      const g = new GroupWrapper(group);
      if (g.isJoined()) {
        return 'my';
      }
      return 'toJoin';
    });
  }, [props.groups]);

  const hasMyGroups = groupsByMembership.my && groupsByMembership.my.length;
  const hasGroupsToJoin =
    groupsByMembership.toJoin &&
    groupsByMembership.toJoin.length &&
    shouldShowGroupsToJoin;
  const hasGroups = hasMyGroups || hasGroupsToJoin;

  return (
    <Box
      className={st(classes.root, {
        withSearch: shouldShowSearch,
        mobile: isMobile,
      })}
      ignoreShadow={isMobile}
      ref={rootEl}
      style={{ height: isMobile ? '100%' : height }}
    >
      {shouldShowSearch ? (
        <Search
          withBorder={true}
          forceOpen={true}
          withCloseButton={true}
          fullWidth={true}
          placeholder={t('groups-web.search.placeholder')}
          onChange={searchGroups}
          className={classes.searchBox}
        />
      ) : null}
      <div className={st(classes.groups, { empty: !hasGroups })}>
        {hasGroups ? renderGroups() : renderNoSearchResults()}
      </div>
      {shouldShowCreateGroupButton ? (
        <div className={classes.createGroupButtonWrapper}>
          <CreateGroupButton
            biOrigin="new_layout_sidebar"
            priority={ButtonPriority.secondary}
            className={classes.createGroupButton}
            onClick={props.onCreateButtonClick}
          >
            {t('groups-web.btn.create-new')}
          </CreateGroupButton>
        </div>
      ) : null}
    </Box>
  );

  function searchGroups(query: string) {
    bi.report(
      groupsSearchForGroup({
        origin: 'sidebar_livesite',
        userEntry: BIUserEntry.SITE,
      }),
    );
    actions.searchGroups(query);
  }

  function navigateToGroup(origin: string) {
    return (groupId: string) => {
      bi.report(
        groupsSelectGroupFromList({
          groupId,
          origin,
          userEntry: BIUserEntry.SITE,
        }),
      );
      actions?.goToGroup(groupId);
    };
  }

  function renderGroups() {
    return (
      <>
        {hasMyGroups ? (
          <Groups
            goToGroup={navigateToGroup('new_layout_groups_sidebar_my_groups')}
            groups={groupsByMembership.my}
            title={t('groups-web.side-bar.my-groups')}
          />
        ) : null}
        {hasMyGroups && hasGroupsToJoin ? (
          <Divider className={classes.divider} />
        ) : null}
        {hasGroupsToJoin ? (
          <Groups
            goToGroup={navigateToGroup(
              'new_layout_groups_sidebar_suggested_groups',
            )}
            groups={groupsByMembership.toJoin}
            title={t('groups-web.side-bar.groups-to-join')}
            withMembershipButton={experiments.enabled(
              'specs.groups.ShowJoinButtonInSidebar',
            )}
            onChangeMembership={setChangeMembershipGroupId}
          />
        ) : null}
      </>
    );
  }

  function renderNoSearchResults() {
    return (
      <NoGroups
        className={classes.noGroups}
        emptyStateHeader={t('groups-web.search.no-results.title')}
        emptyStateText={t('groups-web.search.no-results.text')}
      />
    );
  }
};
